import React, {useContext, useEffect, useState} from 'react';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import moment from 'moment';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const PackageAnnuallyPage = () => {
  const navigate = useNavigate();
  const [overTone, setOvertone] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const [packagee, setPackage] = useState([]);

  const user = JSON.parse(getUser());

  useEffect(() => {
    getPackage();
  }, []);

  const getPackage = () => {
    dataContext.setLoading(true);
    fetchDataFromAPI(
      `features/getAllpackage?type=${'0'}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res', res?.data?.result);
        setPackage(res?.data?.result);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext.setLoading(false);
      });
  };

  // this use Dffect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete Color Type
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `features/deletepackage?id=${deleteId}`,
      'delete',
      '',
      user?.authToken,
    )
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getPackage();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };
  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>intensity</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>

            <li className="breadcrumb-item active">Manufacturer Plan</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-lg-between btn-View">
                  <div>
                    {/* <h3 className=" font-weight-bold">Users List</h3> */}
                  </div>

                  <div className=" text-center ">
                    <button
                      type="submit"
                      className="btn-custom"
                      onClick={() => navigate('/add-annually-package')}>
                      Add Manufacturer Plan
                    </button>
                  </div>
                </div>
                <h5 className="card-title">Manufacturer Data</h5>
                {/* Default Table */}
                <div className="table-responsive">
                  {packagee?.length === 0 ? (
                    <p className="text-center">No data available</p>
                  ) : (
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          {/* <th scope="col">Image</th> */}
                          <th scope="col">Title</th>
                          <th scope="col">Bill</th>
                          <th scope="col">Warning</th>
                          <th scope="col">Price</th>
                          <th scope="col">Sale Price</th>
                          <th scope="col">Discount</th>
                          <th scope="col">Description</th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packagee.map((item, index) => {
                          let warning = item?.warning;
                          let description = item?.description;

                          if (warning && warning.length > 30) {
                            warning = warning.slice(0, 30) + '...';
                          }

                          if (description && description.length > 30) {
                            description = description.slice(0, 30) + '...';
                          }

                          return (
                            <tr className="align-middle">
                              <th scope="row">{index + 1}</th>
                              {/* <td>
                            <img
                              src={
                                item?.image
                                  ? item?.image
                                  : require('../../../assets/images/account.png')
                              }
                              alt="Profile"
                              style={{height: 50, width: 50}}
                              className="rounded-circle"
                            />
                          </td> */}
                              <td>{item?.title}</td>
                              <td>{item?.bill}</td>
                              <td>{warning}</td>
                              <td>{item?.price ? item?.price : '-'}</td>
                              <td>
                                {item?.sale_price ? item?.sale_price : '-'}
                              </td>
                              <td>{item?.discount ? item?.discount : '-'}</td>
                              <td className="monthly_package_desc">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: description,
                                  }}></span>
                              </td>

                              <td className="">
                                <div className="d-flex">
                                  <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={(e) => {
                                      navigate(
                                        `/edit-annually-package/${item?._id}`,
                                      );
                                    }}>
                                    <i class="bi bi-pencil-square"></i>
                                  </div>
                                  <div
                                    role="button"
                                    className="delete-icon btn btn-sm btn-danger"
                                    // onClick={() => deleteContent(item?._id)}
                                    onClick={(e) => {
                                      setDeleteId(item?._id);
                                    }}>
                                    <i class="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
                {/* End Default Table Example */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeleteModal
        show={dataContext.showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Annually Package"
        body="Are you sure you want to delete this record?"
      />
    </main>
  );
};

export default PackageAnnuallyPage;
