import React, {useContext, useEffect, useState} from 'react';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import moment from 'moment';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';
import {Pagination} from 'react-bootstrap';

const HoldListPage = () => {
  const navigate = useNavigate();
  const [holdlist, setHoldList] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const user = JSON.parse(getUser());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getHoldList(currentPage);
  }, [currentPage]);

  const getHoldList = (page) => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `admin/getAllHoldDiamond?page=${page}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res ---->', res);
        setHoldList(res?.data);
        setTotalPages(res?.pages?.totalPages);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getHoldList(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getHoldList(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            className={'custom-pagination'}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
            linkClassName={i === currentPage ? 'link-pagination' : ''}>
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  // this use Dffect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete Color Type
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `admin/deleteHoldDiamond?hold_diamond_id=${deleteId}`,
      'delete',
      '',
      user?.authToken,
    )
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getHoldList();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };
  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>intensity</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>

            <li className="breadcrumb-item active">Hold Data</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-lg-between btn-View">
                  <div>
                    {/* <h3 className=" font-weight-bold">Users List</h3> */}
                  </div>
                </div>
                <h5 className="card-title">Hold Data</h5>
                {/* Default Table */}
                <table className="table table-hover text-wrap">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      {/* <th scope="col">Image</th> */}
                      <th scope="col">Comment</th>
                      <th scope="col">Hours</th>
                      <th scope="col">User Name</th>
                      {/* <th scope="col">Account Type</th> */}
                      <th scope="col">Email</th>
                      <th scope="col">Certificate Number</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {holdlist.length === 0 && (
                      <tr>
                        <td colSpan={12} className="text-center">
                          No Hold Data Available
                        </td>
                      </tr>
                    )}
                    {holdlist.map((item, index) => {
                      return (
                        <tr className="align-middle">
                          <th scope="row">{index + 1}</th>
                          {/* <td>
                          <img
                            src={
                              item?.image
                                ? item?.image
                                : require('../../../assets/images/account.png')
                            }
                            alt="Profile"
                            style={{height: 50, width: 50}}
                            className="rounded-circle"
                          />
                        </td> */}
                          <td>{item?.comment ? item?.comment : '-'}</td>
                          <td>{item?.hours ? item?.hours : '-'}</td>
                          <td>
                            {item?.user_id?.first_name &&
                            item?.user_id?.last_name
                              ? `${item?.user_id?.first_name} ${item?.user_id?.last_name}`
                              : '-'}
                          </td>

                          {/* <td>{item?.user_id?.account_type}</td> */}

                          <td>
                            {item?.user_id?.email ? item?.user_id?.email : '-'}
                          </td>
                          <td>
                            {item?.diamond_id?.cert_num
                              ? item?.diamond_id?.cert_num
                              : '-'}
                          </td>

                          {/* <td className="">
                            <div className="d-flex">
                              <div
                                className="edit-icon btn btn-sm btn-primary me-2"
                                role="button"
                                onClick={(e) => {
                                  navigate(`/edit-over-tone/${item?._id}`, {
                                    state: {user: item},
                                  });
                                }}>
                                <i class="bi bi-pencil-square"></i>
                              </div>
                              <div
                                role="button"
                                className="delete-icon btn btn-sm btn-danger"
                                // onClick={() => deleteContent(item?._id)}
                                onClick={(e) => {
                                  setDeleteId(item?._id);
                                }}>
                                <i class="bi bi-trash"></i>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* End Default Table Example */}
              </div>
            </div>
          </div>
        </div>
        {totalPages > 1 && (
          <Pagination className="align-items-center justify-content-center">
            {currentPage !== 1 && ( // Check if it's not the first page
              <p
                className="text-center mb-sm-0 pe-3 cursor-pointer"
                onClick={handlePrevious}
                role="button">
                Previous
              </p>
            )}
            {getPaginationItems()}
            {currentPage !== totalPages &&
              holdlist?.length > 0 && ( // Check if it's not the last page and there's data
                <p
                  className="text-center mb-sm-0 ps-3 cursor-pointer"
                  onClick={handleNext}
                  role="button">
                  Next
                </p>
              )}
          </Pagination>
        )}
      </section>

      <DeleteModal
        show={dataContext.showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Hold list"
        body="Are you sure you want to delete this record?"
      />
    </main>
  );
};

export default HoldListPage;
