import React, {useEffect} from 'react';
import {io} from 'socket.io-client';
const ChatPage = () => {
  const sockett = io('https://preprod.transectra.com:8443/api/v1/').connect();
  console.log('sockett', sockett?.connected);
  // useEffect(() => {

  // },[])

  return (
    <div className="main" id="main">
      <div className="container-fluid d-flex flex-column vh-100">
        {/* Header */}
        <div
          className="row p-3"
          style={{backgroundColor: '#BC923F', color: '#fff'}}>
          <div className="col">
            <h5>Chat Room</h5>
          </div>
        </div>

        {/* Chat Messages Area */}
        <div className="row flex-grow-1 overflow-auto p-3 bg-light">
          <div className="col">
            <div className="d-flex flex-column">
              {/* Example Messages */}
              <div
                className="align-self-start p-2 rounded mb-2 shadow-sm"
                style={{
                  backgroundColor: '#f8f9fa',
                  color: '#000',
                  maxWidth: '70%',
                }}>
                <strong>User 1:</strong>
                <p>Hello! How are you?</p>
              </div>
              <div
                className="align-self-end p-2 rounded mb-2 shadow-sm"
                style={{
                  backgroundColor: '#BC923F',
                  color: '#fff',
                  maxWidth: '70%',
                }}>
                <strong>You:</strong>
                <p>I’m good, thanks! How about you?</p>
              </div>
              {/* Add more messages here */}
            </div>
          </div>
        </div>

        {/* Message Input Area */}
        <div className="row bg-white p-3 border-top">
          <div className="col">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message..."
              />
              <button
                className="btn"
                style={{backgroundColor: '#BC923F', color: '#ffffff'}}
                type="button">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
