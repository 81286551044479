import React, {useContext, useEffect, useState} from 'react';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import Processing from '../../../components/Processing/Processing';
import {DataContext} from '../../../context/dataContext';
import {getUser} from '../../../utils/constants';
import {Link, useNavigate} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import moment from 'moment';
import {Pagination} from 'react-bootstrap';
import fileDownload from 'js-file-download';

const UserVerifyPage = () => {
  const navigate = useNavigate();
  const [verify, setVerify] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const user = JSON.parse(getUser());

  const [selectedTab, setSelectedTab] = useState(false);
  const [updatingId, setUpdatingId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getVerify(currentPage);
  }, [selectedTab, currentPage]);

  // this use Effect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getVerify = (page) => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `getuser?isverified=${selectedTab}&page=${page}&search=${searchQuery}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res verify', res);
        setVerify(res?.data?.users);
        setTotalPages(res?.data?.totalPages);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`deleteuser?id=${deleteId}`, 'delete', '', user?.authToken)
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getVerify();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  const handlePending = () => {
    setSelectedTab('Pending');
    setCurrentPage(1);
  };

  const handleReady = () => {
    setSelectedTab('Ready_For_Payment');
    setCurrentPage(1);
  };
  const handleActive = () => {
    setSelectedTab('Active');
    setCurrentPage(1);
  };
  const handleRejected = () => {
    setSelectedTab('Rejected');
    setCurrentPage(1);
  };

  const updateStatus = (id, status) => {
    console.log('object update', id, status);

    setUpdatingId(id);
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `verifyuser?id=${id}&isverified=${status}`,
      'put',
      '',
      user?.authToken,
    )
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getVerify();
        setUpdatingId(null);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Status update error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setUpdatingId(null);
        dataContext?.setLoading(false);
      });
  };

  const updateStatusActiveUser = (id, status) => {
    setUpdatingId(id);
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `verifyuser?id=${id}&is_active=${status}`,
      'put',
      '',
      user?.authToken,
    )
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getVerify();
        setUpdatingId(null);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Status update error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setUpdatingId(null);
        dataContext?.setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getVerify(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getVerify(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            className={'custom-pagination'}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
            linkClassName={i === currentPage ? 'link-pagination' : ''}>
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  const getCsv = () => {
    fetchDataFromAPI(
      `getuser?isverified=${selectedTab}&search=${searchQuery}&is_export=true`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        fileDownload(res, 'data.csv');
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Users</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="d-flex mt-3 align-items-center">
                  <div className="col-md-6">
                    <div className="input-group ms-4 rounded-1 overflow-hidden">
                      {/* <label htmlFor="reg" className="form-label">
                          Reg:
                        </label> */}
                      <input
                        className=""
                        type="text"
                        name="query"
                        placeholder="Search"
                        title="Enter search keyword"
                        style={{
                          flex: 1,
                          padding: '8px',
                          border: '1px solid #ccc',
                        }}
                        value={searchQuery}
                        onChange={(event) => setSearchQuery(event.target.value)}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          getVerify();
                          // setCurrentPage(1);
                        }}
                        type="submit"
                        title="Search"
                        style={{
                          backgroundColor: '#BC923F',
                          color: 'white',
                          border: 'none',
                          padding: '8px 15px',
                        }}>
                        <i className="bi bi-search"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="d-flex gap-2 ms-5 align-items-center">
                      <div onClick={() => getCsv()}>
                        {/* <CSVLink data={csv} headers={headers} filename={'users.csv'}> */}
                        {/* <i className="bi bi-share"></i> */}
                        <img
                          role="button"
                          style={{height: 20, width: 25}}
                          src={require('../../../assets/images/export.png')}
                          alt="logo"
                        />
                        {/* </CSVLink> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <ul className="nav">
                    <li className="nav-item">
                      <button
                        className={`nav-link-tab ${
                          selectedTab === false ? 'active' : ''
                        }`}
                        onClick={handlePending}
                        data-bs-toggle="tab"
                        data-bs-target="#driver-app">
                        Pending
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link-tab ${
                          selectedTab === true ? 'active' : ''
                        }`}
                        onClick={handleReady}
                        data-bs-toggle="tab"
                        data-bs-target="#driver-icabbi">
                        Ready For Payment
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link-tab ${
                          selectedTab === true ? 'active' : ''
                        }`}
                        onClick={handleActive}
                        data-bs-toggle="tab"
                        data-bs-target="#driver-icabbi">
                        Active
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link-tab ${
                          selectedTab === true ? 'active' : ''
                        }`}
                        onClick={handleRejected}
                        data-bs-toggle="tab"
                        data-bs-target="#driver-icabbi">
                        Rejected
                      </button>
                    </li>
                  </ul>
                  <hr />

                  <h5 className="card-title">User Data</h5>
                  {verify?.length === 0 ? (
                    <p className="text-center">No data available</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table text-nowrap table-hover">
                        <thead>
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Account Type</th>
                            <th scope="col">Status</th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {verify?.map((item, index) => (
                            <tr className="align-middle" key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item?.first_name}</td>
                              <td>{item?.last_name}</td>
                              <td>{item?.email}</td>
                              <td>{item?.phone}</td>
                              <td>{item?.account_type}</td>
                              <td>
                                <select
                                  style={{width: 100}}
                                  className="form-select form-select-sm"
                                  value={
                                    item?.isverified == false &&
                                    item?.is_active == false
                                      ? 'Pending'
                                      : item?.isverified == true &&
                                        item?.is_active == true
                                      ? 'Active'
                                      : item?.isverified == true &&
                                        item?.is_active == false
                                      ? 'Ready_For_Payment'
                                      : 'Rejected'
                                  }
                                  onChange={(e) =>
                                    updateStatus(item?._id, e.target.value)
                                  }
                                  disabled={updatingId === item?._id}>
                                  <option value="Pending">Pending</option>
                                  <option value="Ready_For_Payment">
                                    Ready For Payment
                                  </option>
                                  <option value="Active">Active</option>
                                  <option value="Rejected">Rejected</option>
                                </select>
                              </td>

                              {/* <td>
                                <select
                                  style={{width: 100}}
                                  className="form-select form-select-sm"
                                  value={item?.is_active ? 'true' : 'false'}
                                  onChange={(e) =>
                                    updateStatusActiveUser(
                                      item?._id,
                                      e.target.value === 'true',
                                    )
                                  }
                                  disabled={updatingId === item?._id}>
                                  <option value="true">Active</option>
                                  <option value="false">InActive</option>
                                </select>
                              </td> */}

                              <td className="">
                                <div className="d-flex">
                                  <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={() => {
                                      navigate(
                                        `/edit-user-verify/${item?._id}`,
                                      );
                                    }}>
                                    <i className="bi bi-pencil-square"></i>
                                  </div>
                                  <div
                                    role="button"
                                    className="delete-icon btn btn-sm btn-danger"
                                    onClick={() => {
                                      setDeleteId(item?._id);
                                    }}>
                                    <i className="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {totalPages > 1 && (
            <Pagination className="align-items-center justify-content-center">
              {currentPage !== 1 && ( // Check if it's not the first page
                <p
                  className="text-center mb-sm-0 pe-3 cursor-pointer"
                  onClick={handlePrevious}
                  role="button">
                  Previous
                </p>
              )}
              {getPaginationItems()}
              {currentPage !== totalPages &&
                verify.length > 0 && ( // Check if it's not the last page and there's data
                  <p
                    className="text-center mb-sm-0 ps-3 cursor-pointer"
                    onClick={handleNext}
                    role="button">
                    Next
                  </p>
                )}
            </Pagination>
          )}
        </section>
        <DeleteModal
          show={dataContext.showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete User"
          body="Are you sure you want to delete this record?"
        />
      </main>
    </div>
  );
};

export default UserVerifyPage;
