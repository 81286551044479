import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {fetchDataFromAPI} from '../../../utils/api';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import ReactQuill from 'react-quill';
import {getUser} from '../../../utils/constants';
const AddPackage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [core, setCore] = useState([]);
  const user = JSON.parse(getUser());
  useEffect(() => {
    getFeatures();
  }, []);

  const handleEditorChange = (html) => {
    formik.setFieldValue('description', html);
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      price: '',
      description: '',
      bill: 'Billed Monthly',
      warning: '',
      discount: '',
      sale_price: '',
      core_features: [
        {
          Key: '',
          Value: '',
        },
      ],
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Please enter title'),
      price: Yup.string().required('Please enter price'),
      description: Yup.string().required('Please enter description'),
      bill: Yup.string().required('Please enter bill'),
      warning: Yup.string().required('Please enter warning'),
      discount: Yup.string().required('Please enter discount'),
      sale_price: Yup.string().required('Please enter sale price'),
      core_features: Yup.array().of(
        Yup.object().shape({
          Key: Yup.string().required('Please select feature'),
          Value: Yup.string().required('Please enter value'),
        }),
      ),
    }),
    onSubmit: (values) => {
      const {
        title,
        price,
        description,
        bill,
        warning,
        discount,
        sale_price,
        core_features,
      } = values;
      console.log('value', values);

      const body = {
        title: title,
        price: price,
        description: description,
        bill: bill,
        warning: warning,
        discount: discount,
        sale_price: sale_price,
        core_features: core_features,
        plan_type: 'Retailer',
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI('features/addpackage', 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/monthly-package');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  const AddFeture = () => {
    formik.setFieldValue('core_features', [
      ...formik.values.core_features,
      {
        Key: '',
        Value: '',
      },
    ]);
  };

  const handleKeyChange = (index, event) => {
    const {name, value} = event.target;
    const updatedFeature = [...formik.values.core_features];
    updatedFeature[index] = {
      ...updatedFeature[index],
      Key: value,
    };
    formik.setFieldValue('core_features', updatedFeature);
  };
  const handleValueChange = (index, event) => {
    const {name, value} = event.target;
    const updatedFeature = [...formik.values.core_features];
    updatedFeature[index] = {
      ...updatedFeature[index],
      Value: value,
    };
    formik.setFieldValue('core_features', updatedFeature);
  };

  const removeFeture = (index) => {
    const values = formik.values.core_features.filter(
      (core_features, i) => i !== index,
    );
    formik.setFieldValue('core_features', values);
  };

  const getFeatures = () => {
    dataContext.setLoading(true);
    fetchDataFromAPI(`features/getAllfeatures`, 'get', '', user?.authToken)
      .then((res) => {
        setCore(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext.setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/monthly-package">Retailer Plan</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Add Retailer Plan</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="title" className="form-label">
                          Title
                        </label>

                        <input
                          name="title"
                          type="text"
                          className="form-control"
                          id="title"
                          value={formik.values.title}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[A-Za-z\s]*$/.test(value)) {
                              formik.setFieldValue('title', value.trimStart());
                            }
                          }}
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="text-danger">
                            {formik.errors.title}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="Role" className="form-label">
                          Bill
                        </label>
                        <div className="input-group">
                          <select
                            name="role"
                            className={`form-select`}
                            id="Role"
                            // value={formik.values.role}
                            // onChange={(e) =>
                            //   formik.setFieldValue(e.target.value)
                            // }

                            {...formik.getFieldProps('bill')}
                            aria-label="Select Bill">
                            {/* <option value="">Select a Bill...</option> */}
                            <option value="Billed Monthly">
                              Billed Monthly
                            </option>
                            {/* <option value="Billed Annually">
                              Billed Annually
                            </option> */}
                            {/* <!-- Add more options as needed --> */}
                          </select>
                        </div>
                        {formik.touched.bill && formik.errors.bill && (
                          <div className="text-danger">
                            {formik.errors.bill}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="warning" className="form-label">
                          Warning
                        </label>

                        <input
                          name="warning"
                          type="text"
                          className="form-control"
                          id="warning"
                          value={formik.values.warning}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'warning',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.warning && formik.errors.warning && (
                          <div className="text-danger">
                            {formik.errors.warning}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="discount" className="form-label">
                          Discount
                        </label>

                        <input
                          name="discount"
                          type="text"
                          className="form-control"
                          id="discount"
                          value={formik.values.discount}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'discount',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.discount && formik.errors.discount && (
                          <div className="text-danger">
                            {formik.errors.discount}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="price" className="form-label">
                          Price
                        </label>

                        <input
                          name="price"
                          type="text"
                          className="form-control"
                          id="price"
                          value={formik.values.price}
                          onChange={(e) => {
                            // const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue(
                              'price',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.price && formik.errors.price && (
                          <div className="text-danger">
                            {formik.errors.price}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="sale_price" className="form-label">
                          Sale Price
                        </label>

                        <input
                          name="sale_price"
                          type="text"
                          className="form-control"
                          id="sale_price"
                          value={formik.values.sale_price}
                          onChange={(e) => {
                            // const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue(
                              'sale_price',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.sale_price &&
                          formik.errors.sale_price && (
                            <div className="text-danger">
                              {formik.errors.sale_price}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.description}
                            onChange={handleEditorChange}
                            style={{height: 100}}

                            // style={{height: 200}}
                          />
                        </div>
                        <div>
                          {formik.touched.description &&
                            formik.errors.description && (
                              <div className="text-danger">
                                {formik.errors.description}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      {formik.values.core_features.map((item, index) => (
                        <div>
                          <div className="mt-4 d-flex align-items-center justify-content-between">
                            <div />
                            <p className="font-medium text-[#1c1d31] m-0 fw-bold text-center">
                              Feature {index + 1}
                            </p>
                            {index !== 0 ? (
                              <span
                                className="text-red-600"
                                role="button"
                                onClick={() => removeFeture(index)}>
                                <i
                                  className="bi bi-dash-circle-fill"
                                  style={{color: 'red'}}></i>
                              </span>
                            ) : (
                              <div />
                            )}
                          </div>

                          <div className="flex flex-col mb-3">
                            <label className="label-style">Feture Value</label>
                            <input
                              className="form-control mt-2"
                              placeholder="Enter value"
                              name="name"
                              value={formik.values.core_features[index].Value}
                              onChange={(event) =>
                                handleValueChange(index, event)
                              }
                            />
                            {formik.errors.core_features &&
                              formik.errors.core_features[index] &&
                              formik.touched.core_features &&
                              formik.touched.core_features[index] && (
                                <div style={{color: 'red'}}>
                                  {formik.errors.core_features[index].Value}
                                </div>
                              )}
                          </div>

                          <div>
                            <label htmlFor="about" className="form-label">
                              Select Core Feature
                            </label>

                            <select
                              className="form form-select"
                              value={formik.values.core_features[index].Key}
                              onChange={(event) =>
                                handleKeyChange(index, event)
                              }>
                              <option value="">Select Core Feature...</option>
                              {core.map((item) => (
                                <option value={item?._id}>{item?.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}
                      <div className="d-flex justify-content-center mt-2">
                        <button className="btn-custom" onClick={AddFeture}>
                          <i className="bi bi-plus me-1"></i>
                          Add Feature
                        </button>
                      </div>
                      {/* <div className="col-md-6  ">
                        <label htmlFor="about" className="form-label">
                          Select Core Feature
                        </label>

                        <select
                          className="form form-select"
                          {...formik.getFieldProps('core_features')}>
                          <option value="">Select Core Feature...</option>
                          {core.map((item) => (
                            <option value={item?._id}>{item?.name}</option>
                          ))}
                        </select>
                        {formik.touched.core_features &&
                          formik.errors.core_features && (
                            <div className="text-danger">
                              {formik.errors.core_features}
                            </div>
                          )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="value" className="form-label">
                          Value
                        </label>

                        <input
                          name="value"
                          type="text"
                          className="form-control"
                          id="value"
                          value={formik.values.value}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'value',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.value && formik.errors.value && (
                          <div className="text-danger">
                            {formik.errors.value}
                          </div>
                        )}
                      </div> */}
                      {/* {coreFeatures.map((feature, index) => (
                        <div
                          className="row mb-3 align-items-center"
                          key={index}>
                          <div className="col-md-5">
                            <label
                              htmlFor={`coreFeature${index}`}
                              className="form-label">
                              Select Core Feature
                            </label>
                            <select
                              className="form form-select"
                              value={feature.Key}
                              onChange={(e) =>
                                handleCoreFeatureChange(index, e)
                              }>
                              <option value="">Select Core Feature...</option>
                              {core.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-5">
                            <label
                              htmlFor={`value${index}`}
                              className="form-label">
                              Value
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={feature.Value}
                              onChange={(e) => handleValueChange(index, e)}
                            />
                          </div>
                          <div className="col-md-2">
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDelete(index)}>
                              Delete
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-center">
                        <button
                          className="btn btn-primary"
                          onClick={handleAddMore}>
                          Add One More
                        </button>
                      </div> */}
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        onClick={formik.handleSubmit}
                        label="Add Plan"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddPackage;
