import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

const Sidebar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState(null);
  const location = useLocation();

  const routes = [
    {title: 'Dashboard', path: '/dashboard'},
    {
      title: 'Diamond',
      content: [
        {title: 'Shape', path: '/shape'},
        {title: 'Weight (In Carat)', path: '/carat'},
        {title: 'Color Type', path: '/color-type'},
        {title: 'Colour', path: '/color'},
        {title: 'Clarity', path: '/clarity'},
        {title: 'Eye Clean', path: '/eyeclean'},
        {title: 'Finish', path: '/finish'},
        {title: 'Cutting', path: '/cutting'},
        {title: 'Polishing', path: '/polishing'},
        {title: 'Symmetry', path: '/symmetry'},
        {title: 'Fluorescence', path: '/fluorescence'},
        {title: 'Grading', path: '/grading'},
        {title: 'Report Number', path: '/reportnumber'},
        {title: 'Cert Comment', path: '/certcomment'},
        {title: 'Location', path: '/location'},
        {title: 'Rough Source', path: '/roughsource'},
        {title: 'Source Provider', path: '/sourceProvider'},
        {title: 'Gridle', path: '/gridle'},
        {title: 'Culet', path: '/culet'},
        {title: 'Symbol', path: '/key-symbol'},
        {title: 'Key Type', path: '/key'},
        {title: 'Shade', path: '/shade'},
        {title: 'Shade Type', path: '/shade-type'},
        {title: 'Brand', path: '/brand'},
        {title: 'Transcetra Lot', path: '/transcetra-lot'},
        {title: 'CuletCondition', path: '/culetcondition'},
        {title: 'Treatment', path: '/treatment'},
        {title: 'Treatment Type', path: '/treatment-type'},
        {title: 'Showonly', path: '/showonly'},
      ],
    },
    {
      title: 'Packages',
      content: [
        {title: 'Retailer Plan', path: '/monthly-package'},
        {title: 'Manufacturer Plan', path: '/annually-package'},
        {title: 'Core Feature', path: '/core-feature'},
      ],
    },
    {
      title: 'Rapaport Prices',
      content: [
        {title: 'Rapaport Price List', path: '/rapaport-price-list'},
        {title: 'RapNet Price List', path: '/repnet-price-list'},
      ],
    },
    {
      title: 'Company',
      content: [
        {title: 'Company Size', path: '/company-size'},
        {title: 'Company Type', path: '/company-type'},
      ],
    },

    {
      title: 'T & C',
      content: [
        {title: 'Liecence Aggrement', path: '/liecence-aggrement'},
        {title: 'Code of Conduct', path: '/code-of-conduct'},
        {title: 'Privacy Policy', path: '/privacy-policy'},
        {title: 'Term and Condition', path: '/term-and-condition'},
      ],
    },
    {title: 'Add Diamond', path: '/get-diamond'},
    {title: 'User Verify', path: '/user-verify'},
    {title: 'Sales Executive', path: '/sales-executive'},
    {title: 'Buyer Request', path: '/buyer-request'},
    {title: 'Regional Offices', path: '/regional-offices'},
    {title: 'Country Price', path: '/country-price'},
    {title: 'Markup Price', path: '/markup-price'},
    {title: 'Transectra Price', path: '/price-master'},
    {title: 'Announcement', path: '/announcement'},
    {title: 'User Asking', path: '/user-asking'},
    {title: 'Contact Form', path: '/contact-form'},
    {title: 'Buy List', path: '/buy-list'},
    {title: 'Order List', path: '/order-list'},
    {title: 'Hold List', path: '/hold-list'},
    {title: 'Total Purchase', path: '/total-purchase'},
    // {title: 'Chat User', path: '/chat-user'},
  ];

  const handleCollapse = (title) => {
    setOpenDropdown(openDropdown === title ? null : title);
  };

  useEffect(() => {
    routes.forEach((route, index) => {
      if (route.content) {
        route.content.forEach((subRoute, subIndex) => {
          if (subRoute.path === location.pathname) {
            setSelectedIndex(index);
            setSelectedSubIndex(subIndex);
            setOpenDropdown(route.title);
          }
        });
      } else {
        if (route.path === location.pathname) {
          setSelectedIndex(index);
          setSelectedSubIndex(null);
        }
      }
    });
  }, [location.pathname]);

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {routes.map((val, index) => {
          if (val.content) {
            const isOpened = openDropdown === val.title;

            return (
              <li className="nav-item" key={index}>
                <Link
                  className={isOpened ? 'nav-link' : 'nav-link collapsed'}
                  data-bs-target={`#${val.title.toLowerCase()}-nav`}
                  data-bs-toggle="collapse"
                  onClick={() => handleCollapse(val.title)}>
                  <i
                    className={
                      val.title === 'Diamond'
                        ? 'bi bi-gem'
                        : val.title === 'Packages'
                        ? 'bi bi-boxes'
                        : val.title === 'Rapaport Prices'
                        ? 'bi bi-tags-fill'
                        : val.title === 'Company'
                        ? 'bi bi-buildings'
                        : 'bi bi-terminal'
                    }></i>
                  <span>{val.title}</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
                <ul
                  id={`${val.title.toLowerCase()}-nav`}
                  className={`nav-content collapse ${isOpened ? 'show' : ''}`}
                  data-bs-parent="#sidebar-nav">
                  {val.content.map((item, subIndex) => (
                    <li key={subIndex}>
                      <Link
                        to={item.path}
                        className={
                          selectedSubIndex === subIndex &&
                          selectedIndex === index
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        onClick={() => {
                          setSelectedIndex(index);
                          setSelectedSubIndex(subIndex);
                        }}>
                        <i className="bi bi-circle-fill"></i>
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            );
          }

          return (
            <li className="nav-item" key={index}>
              <Link
                className={
                  selectedIndex === index
                    ? 'nav-link active text-white'
                    : 'nav-link'
                }
                to={val.path}
                onClick={() => {
                  setSelectedIndex(index);
                  setSelectedSubIndex(null);
                }}>
                {val.title === 'Dashboard' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-receipt text-white'
                        : 'bi bi-receipt'
                    }></i>
                ) : val.title === 'Add Diamond' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-gem text-white'
                        : 'bi bi-gem'
                    }></i>
                ) : val.title === 'User Verify' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-person-check text-white'
                        : 'bi bi-person-check'
                    }></i>
                ) : val.title === 'Sales Executive' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-person-vcard text-white'
                        : 'bi bi-person-vcard'
                    }></i>
                ) : val.title === 'Buyer Request' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-bag text-white'
                        : 'bi bi-bag'
                    }></i>
                ) : val.title === 'Regional Offices' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-building text-white'
                        : 'bi bi-building'
                    }></i>
                ) : val.title === 'Country Price' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-cash-coin text-white'
                        : 'bi bi-cash-coin'
                    }></i>
                ) : val.title === 'Markup Price' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-cash-stack text-white'
                        : 'bi bi-cash-stack'
                    }></i>
                ) : val.title === 'Announcement' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-megaphone text-white'
                        : 'bi bi-megaphone'
                    }></i>
                ) : val.title === 'Transectra Price' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-x-diamond text-white'
                        : 'bi bi-x-diamond'
                    }></i>
                ) : val.title === 'User Asking' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-broadcast-pin text-white'
                        : 'bi bi-broadcast-pin'
                    }></i>
                ) : val.title === 'Contact Form' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-person-lines-fill text-white'
                        : 'bi bi-person-lines-fill'
                    }></i>
                ) : val.title === 'Buy List' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-bag text-white'
                        : 'bi bi-bag'
                    }></i>
                ) : val.title === 'Order List' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-list-ul text-white'
                        : 'bi bi-list-ul'
                    }></i>
                ) : val.title === 'Hold List' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-h-circle text-white'
                        : 'bi bi-h-circle'
                    }></i>
                ) : val.title === 'Total Purchase' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-handbag text-white'
                        : 'bi bi-handbag'
                    }></i>
                ) : val.title === 'Chat User' ? (
                  <i
                    className={
                      selectedIndex === index
                        ? 'bi bi-chat-dots text-white'
                        : 'bi bi-chat-dots'
                    }></i>
                ) : null}
                <span>{val.title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
