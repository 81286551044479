import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import moment from 'moment';
import {DataContext} from '../../../context/dataContext';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const SalesExecutive = () => {
  const navigate = useNavigate();
  const [executive, setExecutive] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const user = JSON.parse(getUser());

  useEffect(() => {
    getSales();
  }, []);

  // this use Dffect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  // get Shape api call
  const getSales = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('contact', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res', res?.data);
        setExecutive(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete executive
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`contact?id=${deleteId}`, 'delete', '', user?.authToken)
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getSales();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>Shape</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>

            <li className="breadcrumb-item active">Sales Executive</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-lg-between btn-View">
                  <div>
                    {/* <h3 className=" font-weight-bold">Users List</h3> */}
                  </div>

                  <div className=" text-center ">
                    <button
                      type="submit"
                      className="btn-custom"
                      onClick={() => navigate('/add-sales-executive')}>
                      Add Sales Executive
                    </button>
                  </div>
                </div>
                <h5 className="card-title">Sales Executive Data</h5>
                {/* Default Table */}
                {executive?.length === 0 ? (
                  <p className="text-center">No data available</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table text-nowrap table-hover">
                      <thead>
                        <tr>
                          <th scope="col">No</th>

                          <th scope="col">Profile</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Whatsapp</th>
                          <th scope="col">Skype</th>
                          <th scope="col">Email Link</th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {executive.map((item, index) => {
                          return (
                            <tr className="align-middle">
                              <th scope="row">{index + 1}</th>

                              <td>
                                <img
                                  src={
                                    item?.profile_image
                                      ? item?.profile_image
                                      : require('../../../assets/images/logo1.png')
                                  }
                                  alt="Profile"
                                  style={{
                                    height: 50,
                                    width: 50,
                                    // backgroundColor: 'gray',
                                    // borderRadius: 25,
                                  }}
                                  // className="rounded-circle"
                                />
                              </td>
                              <td>{item?.first_name}</td>
                              <td>{item?.last_name}</td>
                              <td>{item?.email}</td>
                              <td>{item?.phone ? item?.phone : '-'}</td>
                              <td>{item?.whatsapp ? item?.whatsapp : '-'}</td>
                              <td>
                                {' '}
                                <button
                                  className="btn btn-link"
                                  onClick={() =>
                                    window.open(item?.skype, '_blank')
                                  }>
                                  <i class="bi bi-skype fs-3"></i>
                                </button>
                              </td>
                              <td>
                                {' '}
                                <button
                                  className="btn btn-link"
                                  onClick={() =>
                                    window.open(
                                      `mailto:${item?.email_link}`,
                                      '_blank',
                                    )
                                  }>
                                  <i class="bi bi-envelope-at-fill fs-3"></i>
                                </button>
                              </td>

                              <td className="">
                                <div className="d-flex">
                                  <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={(e) => {
                                      navigate(
                                        `/edit-sales-executive/${item?._id}`,
                                      );
                                    }}>
                                    <i class="bi bi-pencil-square"></i>
                                  </div>
                                  <div
                                    role="button"
                                    className="delete-icon btn btn-sm btn-danger"
                                    // onClick={() => deleteContent(item?._id)}
                                    onClick={(e) => {
                                      setDeleteId(item?._id);
                                    }}>
                                    <i class="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {/* End Default Table Example */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeleteModal
        show={dataContext.showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Sales Executive"
        body="Are you sure you want to delete this record?"
      />
    </main>
  );
};

export default SalesExecutive;
