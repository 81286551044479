import React, {useContext, useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {FormGroup} from 'react-bootstrap';
import * as Yup from 'yup';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const EditSalesExecutive = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [url, setURL] = useState(null);
  const user = JSON.parse(getUser());
  const state = useParams();
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  useEffect(() => {
    getExecutive();
  }, []);

  // get Shape api call
  const getExecutive = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`contact?id=${state?.id}`, 'get', '', user?.authToken)
      .then((res) => {
        console.log('res -->', res?.data);

        formik.setFieldValue('first_name', res?.data?.first_name);
        formik.setFieldValue('last_name', res?.data?.last_name);
        formik.setFieldValue('email', res?.data?.email);
        formik.setFieldValue('email_link', res?.data?.email_link);
        formik.setFieldValue('phone_no', res?.data?.phone_no);
        formik.setFieldValue('skype', res?.data?.skype);
        formik.setFieldValue('whatsapp', res?.data?.whatsapp);
        formik.setFieldValue('profile', res?.data?.profile_image);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  // const getData = () => {
  //   dataContext.setLoading(true);
  //   formik.setFieldValue('image', data?.image);
  //   formik.setFieldValue('shapeName', data?.name);
  //   dataContext.setLoading(false);
  // };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      email_link: '',
      phone_no: '',
      skype: '',
      whatsapp: '',
      newPass: '',
      conNewPass: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.mixed().required('Please enter first name'),
      last_name: Yup.mixed().required('Please enter last name'),
      email: Yup.string()
        .email('Invalid email')
        .required('Please enter your email'),
      // email_link: Yup.string().required('Enter email link'),
      // phone_no: Yup.string().required('Enter email link'),
      // skype: Yup.string().required('Enter skype link'),
      // whatsapp: Yup.string().required('Enter enter whatsapp number'),
      newPass: Yup.string()
        // Scenario 1: No validation restrictions
        // .notRequired()

        // Scenario 2: Relaxed validation criteria
        .min(8, 'Password must be at least 8 characters long.')
        .notRequired(),

      conNewPass: Yup.string()
        .oneOf([Yup.ref('newPass'), null], 'Passwords must match.')
        .notRequired(),
      profile: Yup.mixed().notRequired(),
    }),
    onSubmit: (values) => {
      const {
        last_name,
        first_name,
        email,
        email_link,
        phone_no,
        skype,
        whatsapp,
        conNewPass,
      } = values;

      const body = {
        first_name: first_name,
        last_name: last_name,
        profile_image: url,
        email: email,
        phone_no: phone_no,
        // email_link: email_link,
        // skype: skype,
        // whatsapp: whatsapp,
        password: conNewPass,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI(
          `contact?id=${state?.id}`,
          'put',
          body,
          user?.authToken,
        )
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/sales-executive');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  const handleFileChangePdf = (e) => {
    formik.setFieldValue('profile', e.target.files[0]);
    // const pdfFile = e?.target?.files[0];
    const data = new FormData();
    data.append('image', e?.target?.files[0]);
    // Reset value for the pdfFile field
    // Clear any validation error for the pdfFile field
    dataContext.setLoading(true);
    fetchDataFromAPI('upload', 'post', data, '')
      .then((res) => {
        console.log('res: ', res?.data);

        setURL(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext.setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/sales-executive">Sales Executive</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit Sales Executive</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                          First Name
                        </label>

                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          value={formik.values.first_name}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[A-Za-z\s]*$/.test(value)) {
                              formik.setFieldValue(
                                'first_name',
                                value.trimStart(),
                              );
                            }
                          }}
                        />
                        {formik.touched.first_name &&
                          formik.errors.first_name && (
                            <div className="text-danger">
                              {formik.errors.first_name}
                            </div>
                          )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                          Last Name
                        </label>

                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          value={formik.values.last_name}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[A-Za-z\s]*$/.test(value)) {
                              formik.setFieldValue(
                                'last_name',
                                value.trimStart(),
                              );
                            }
                          }}
                        />
                        {formik.touched.last_name &&
                          formik.errors.last_name && (
                            <div className="text-danger">
                              {formik.errors.last_name}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>

                        <input
                          email="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone_no" className="form-label">
                          Phone
                        </label>

                        <input
                          name="phone_no"
                          type="text"
                          className="form-control"
                          id="phone_no"
                          value={formik.values.phone_no}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue('phone_no', value.trimStart());
                          }}
                          maxLength={10}
                        />
                        {formik.touched.phone_no && formik.errors.phone_no && (
                          <div className="text-danger">
                            {formik.errors.phone_no}
                          </div>
                        )}
                      </div>

                      {/* <div className="col-md-4">
                        <label htmlFor="skype" className="form-label">
                          Skype
                        </label>

                        <input
                          name="skype"
                          type="text"
                          className="form-control"
                          id="skype"
                          value={formik.values.skype}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'skype',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.skype && formik.errors.skype && (
                          <div className="text-danger">
                            {formik.errors.skype}
                          </div>
                        )}
                      </div> */}
                    </div>

                    {/* <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="email_link" className="form-label">
                          Email Link
                        </label>

                        <input
                          name="email_link"
                          type="text"
                          className="form-control"
                          id="email_link"
                          value={formik.values.email_link}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email_link',
                              e.target.value.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.email_link &&
                          formik.errors.email_link && (
                            <div className="text-danger">
                              {formik.errors.email_link}
                            </div>
                          )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="whatsapp" className="form-label">
                          Whatsapp
                        </label>

                        <input
                          name="whatsapp"
                          type="text"
                          className="form-control"
                          id="whatsapp"
                          value={formik.values.whatsapp}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue('whatsapp', value.trimStart());
                          }}
                          maxLength={10}
                          //   pattern="[0-9]*"
                        />
                        {formik.touched.whatsapp && formik.errors.whatsapp && (
                          <div className="text-danger">
                            {formik.errors.whatsapp}
                          </div>
                        )}
                      </div>
                    </div> */}

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="pdfFile" className="form-label">
                          Choose Profile Image
                        </label>
                        <FormGroup>
                          <input
                            className="form-control"
                            type="file"
                            name="pdfFile"
                            accept="image/*"
                            id="pdfFile"
                            onChange={handleFileChangePdf}
                          />
                          {formik.touched.profile &&
                            formik.errors.profile &&
                            !url && (
                              <div className="text-danger">
                                {formik.errors.profile}
                              </div>
                            )}
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          New Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="newPass"
                            type={showNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="newPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'newPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowNewPass(!showNewPass)}>
                            {showNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.newPass && formik.errors.newPass && (
                          <div className="text-danger">
                            {formik.errors.newPass}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Confirm Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="conNewPass"
                            type={showConNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="conNewPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'conNewPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowConNewPass(!showConNewPass)}>
                            {showConNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.conNewPass &&
                          formik.errors.conNewPass && (
                            <div className="text-danger">
                              {formik.errors.conNewPass}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        onClick={formik.handleSubmit}
                        label="Edit Sales Executive"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditSalesExecutive;
